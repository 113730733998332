<template>
  <div fluid :class="mobile ? 'shipMobile' : 'ship'">
    <v-card tile flat class="first">
      <div style="position: absolute; left: 10%; top: 10%; contain: content; line-height: 1.2;">
      <div style="background: linear-gradient(to right, rgb(0, 0, 0, 0.2), rgb(0, 0, 0, 0.0)); height: 100%; width: 100%; position: absolute;"></div>
        <div style="font-size: 4rem;font-family: DIN_Regular, Arial, Verdana, sans-serif !important;">Revolutionising Logistics</div>
        <div style="font-size: 2rem;font-family: DIN_Regular, Arial, Verdana, sans-serif !important;">Utilizing AI and Big Data Precision</div>
      </div>
      <div style="position: absolute; left: 10%; top: 30%; contain: content; line-height: 1.2;">
        <div style="font-size: 1.6rem;font-family: DIN_Regular, Arial, Verdana, sans-serif !important;">By Invite Only</div>
        <v-btn @click="goTo('contact')" class="mt-2" color="#224b74" style="color: white;">Contact Us</v-btn>
      </div>
      <div class="loginCard">
        <v-img src="../../public/Loglive_512x512.png" style="aspect-ratio: 1.1/1; margin: 20px auto;" height="150px"></v-img>
        <div style="font-size: 2.2rem; margin: -5px auto; width: 100%; text-align: center;" class="logo">LØGLIVE</div>
        <div>
          <div>
            <div style="font-size: 1.2rem; width: 100%; text-align: center;" class="mt-6 mb-3">The Ecosystem of Trust</div>
          </div>
          <div style="width: 100%; display: flex; flex-direction: column; align-items: center;">
            <v-text-field dense label="Email Address" prepend-inner-icon="mdi-email" hide-details outlined color="white" style="width: 90%;"> </v-text-field>
            <v-text-field dense :type="showPassword ? 'text' : 'password'" label="Password" prepend-inner-icon="mdi-lock" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" hide-details outlined color="white" style="width: 90%;"> </v-text-field>
            <v-btn @click="login" small class="px-15" color="#224b74" style="color: white; margin: 2vh auto 2vh auto;">Login</v-btn>
            <v-btn text style="color: #224b74;">Forgot Password</v-btn>
            <div style="width: 50%; height: 40px; border-radius: 50px; background-color: #ffffff; margin: 2vh auto 1vh auto;"></div>
            <span style="font-size: 1rem; text-align: center;">
              By signing in you agree to Løglive's <br><a href="/terms" style="color: #224b74;">terms and conditions</a>
            </span>
          </div>
        </div>
      </div>
      <div id="canvasParent" class="canvasParent">
            <canvas id="canvas1" class="canvas1"></canvas>
        </div>
    </v-card>
    <v-transition></v-transition>
    <div></div>
    <!-- <div class="gradientRow">
    <v-row style="background-color: rgba(0, 0, 0, 0) !important;" >
        <v-card tile flat class="second d-flex justify-center align-center" style="flex-direction: column;">
            <v-row class="mb-8">
                <v-col cols="12" sm="12" md="12" lg="12">
                    <div id="canvasParent" class="canvasParent">
                        <canvas id="canvas1" class="canvas1"></canvas>
                    </div>
                    <div class="sentinel" id="sentinel"></div>
                    <span :class="mobile ? 'midMobile' : 'midText'" style="margin: 10vh 0 2vh 0">A COLLABORATIVE BUSINESS PLATFORM</span>
                    <v-row class="pa-0 d-flex justify-center">
                        <v-col cols="12" sm="10" md="9" lg="9" class=" px-5 center">
                            <span class="xSmallText" style="text-align: center;">
                                <div>In our world dependant on rapid global trade, the pressing challenge of outdated logistics technology hinders progress. That's where we come in.</div>
                                Introducing LØGLIVE – a powerful fusion of Artificial Intelligence and Big Data – redefining logistics by enhancing efficiency and reliability on a global scale. Harness the future of logistics with our cutting-edge solution.
                            </span>
                        </v-col>
                    </v-row>
                    <v-row :style="mobile ? '' : {'width': '90vw'}">
                        <v-col cols="12" sm="12" md="3" lg="3" :class="mobile ? 'center mb-4':'center'">
                            <v-card class="infoHoverCard  documents">
                                <div class="gradientDiv">
                                    <span class="cardTextHeader mb-1">Documentation</span>
                                    <span class="descript">Automated Data Capturing</span>
                                    <span class="descript">File Generation</span>
                                    <span class="descript">Version Control</span>
                                    <span class="descript">Cloud Storage</span>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" lg="3" :class="mobile ? 'center mb-4':'center pa-0'">
                            <v-card class="infoHoverCard transport">
                                <div class="gradientDiv">
                                    <span class="cardTextHeader mb-1">Transportation</span>
                                    <span class="descript">Driver Performance Measuring</span>
                                    <span class="descript">Integrated Vehicle Tracking</span>
                                    <span class="descript">Automatic Status Updates</span>
                                    <span class="descript">Smart Allocation</span>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" lg="3" :class="mobile ? 'center mb-4':'center pa-0'">
                            <v-card class="infoHoverCard booking">
                                <div class="gradientDiv">
                                    <span class="cardTextHeader mb-1">Bookings</span>
                                    <span class="descript">Automated Booking Process</span>
                                    <span class="descript">In-Depth Container Overview</span>
                                    <span class="descript">Seamless Pre-Advising</span>
                                    <span class="descript">Instant Client Reports</span>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" lg="3" :class="mobile ? 'center mb-4':'center pa-0'">
                            <v-card class="infoHoverCard shipment">
                                <div class="gradientDiv">
                                    <span class="cardTextHeader mb-1">Shipments</span>
                                    <span class="descript">Vessel Tracking</span>
                                    <span class="descript">Port Monitoring</span>
                                    <span class="descript">Telemetry Insights</span>
                                    <span class="descript">Stack Dates </span>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row> -->
            <!-- <v-row no-gutters>
                <v-col cols="12">
                    <div class="midText mb-8">
                        <div class="text-md-h2 text-h4">One System</div>
                        <div class="text-md-h4 text-h5">Complete Logistical Control</div>
                    </div>
                    <div>
                        <v-img class="d-felx mx-auto" :max-width="mobile ? '20%' : '60%'" style="box-shadow: rgba(0, 0, 0, 0.25) 0px 15px 10px -10px, rgba(0, 0, 0, 0.3) 0px 15px 10px -10px;" src="../../public/LogliveMock.png"></v-img>
                    </div>
                </v-col>
            </v-row> -->

            <!-- <v-row>
                <v-col cols="12">
                    <div style="height: inherit; width: inherit; display: flex; justify-content: center; align-items: center;">
                        <div style="width: 65vw; height: 60vh; display: flex; justify-content: center; align-items: center; flex-direction: column">
                            <v-row>
                                <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center align-center">
                                    <span class="bigText">A PLATFORM FOR THE FUTURE</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6" lg="6" class="xSmallText">By leveraging AI and big data, our platform offers a wealth of invaluable insights and optimizations that help businesses thrive in a competitive market. The platform has already made a significant impact, servicing around 35% of the South African industry as well as assisting governments globally with verification of information, often without the stakeholders even realizing it.</v-col>
                                <v-col cols="12" md="6" lg="6" class="xSmallText">In addition to the technological prowess of our platform, we recognize the importance of fostering a social community where businesses can collaborate, share knowledge, and form strategic partnerships. Our platform offers an interactive and user-friendly environment where stakeholders can communicate, engage, and work together to overcome the challenges they face collectively.</v-col>
                                <v-col cols="12" md="6" lg="6" class="xSmallText">One of the key features of our platform is the utilization of multiple data sources, which enables us to validate and cross-reference information to ensure the highest level of accuracy. With this approach, we can provide real-time updates, predictive analytics, and tailored recommendations, empowering businesses to make data-driven decisions with confidence. In turn, this leads to increased efficiency, reduced costs, and ultimately, a more sustainable and profitable industry.</v-col>
                                <v-col cols="12" md="6" lg="6" class="xSmallText">As a result, our platform not only streamlines the logistics process but also fosters a spirit of collaboration and innovation that drives the industry forward. In this ever-evolving landscape, staying ahead of the curve is essential, and our platform provides the tools and resources necessary for businesses to excel.</v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
            </v-row> -->

            <!-- <v-row>
                <v-col cols="12">
                    <div class="bigText text-h3 text-center">A PLATFORM FOR THE FUTURE</div>
                </v-col>
                <v-col>
                    <v-container style="max-width:66.66%">
                    <v-row>
                        <v-col cols="12" md="6" class="xSmallText">By leveraging AI and big data, our platform offers a wealth of invaluable insights and optimizations that help businesses thrive in a competitive market. The platform has already made a significant impact, servicing around 35% of the South African industry as well as assisting governments globally with verification of information, often without the stakeholders even realizing it.</v-col>
                        <v-col cols="12" md="6" class="xSmallText">In addition to the technological prowess of our platform, we recognize the importance of fostering a social community where businesses can collaborate, share knowledge, and form strategic partnerships. Our platform offers an interactive and user-friendly environment where stakeholders can communicate, engage, and work together to overcome the challenges they face collectively.</v-col>
                        <v-col cols="12" md="6" class="xSmallText">One of the key features of our platform is the utilization of multiple data sources, which enables us to validate and cross-reference information to ensure the highest level of accuracy. With this approach, we can provide real-time updates, predictive analytics, and tailored recommendations, empowering businesses to make data-driven decisions with confidence. In turn, this leads to increased efficiency, reduced costs, and ultimately, a more sustainable and profitable industry.</v-col>
                        <v-col cols="12" md="6" class="xSmallText">As a result, our platform not only streamlines the logistics process but also fosters a spirit of collaboration and innovation that drives the industry forward. In this ever-evolving landscape, staying ahead of the curve is essential, and our platform provides the tools and resources necessary for businesses to excel.</v-col>\
                    </v-row>
                </v-container>
                </v-col>
            </v-row> -->
        <!-- </v-card>
    </v-row> -->

    <!-- <v-row no-gutters class="system">
        <v-col cols="12">
            <div class="midText mb-8">
                <div class="text-md-h2 text-h4">One System</div>
                <div class="text-md-h4 text-h5">Complete Logistical Control</div>
            </div>
            <div>
                <v-img eager class="d-felx mx-auto" :max-width="mobile ? '90%' : '60%'" style="box-shadow: rgba(0, 0, 0, 0.25) 0px 15px 10px -10px, rgba(0, 0, 0, 0.3) 0px 15px 10px -10px;" src="../../public/LogliveMock.png"></v-img>
            </div>
        </v-col> -->
        <!-- <v-col cols="12" sm="12" md="12" lg="12">
                <div style="height: inherit; width: inherit; display: flex; justify-content: center; align-items: center;">

                    <div style="width: 65vw; height: 60vh; display: flex; justify-content: center; align-items: center; flex-direction: column">
                        <v-row>
                            <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center align-center">
                                <span class="bigText">A PLATFORM FOR THE FUTURE</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" lg="6" class="xSmallText">By leveraging AI and big data, our platform offers a wealth of invaluable insights and optimizations that help businesses thrive in a competitive market. The platform has already made a significant impact, servicing around 35% of the South African industry as well as assisting governments globally with verification of information, often without the stakeholders even realizing it.</v-col>
                            <v-col cols="12" md="6" lg="6" class="xSmallText">In addition to the technological prowess of our platform, we recognize the importance of fostering a social community where businesses can collaborate, share knowledge, and form strategic partnerships. Our platform offers an interactive and user-friendly environment where stakeholders can communicate, engage, and work together to overcome the challenges they face collectively.</v-col>
                            <v-col cols="12" md="6" lg="6" class="xSmallText">One of the key features of our platform is the utilization of multiple data sources, which enables us to validate and cross-reference information to ensure the highest level of accuracy. With this approach, we can provide real-time updates, predictive analytics, and tailored recommendations, empowering businesses to make data-driven decisions with confidence. In turn, this leads to increased efficiency, reduced costs, and ultimately, a more sustainable and profitable industry.</v-col>
                            <v-col cols="12" md="6" lg="6" class="xSmallText">As a result, our platform not only streamlines the logistics process but also fosters a spirit of collaboration and innovation that drives the industry forward. In this ever-evolving landscape, staying ahead of the curve is essential, and our platform provides the tools and resources necessary for businesses to excel.</v-col>
                        </v-row>
                    </div>
                </div>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center align-center" style="flex-direction: column;">
            <div style="height: 160px; width: 160px; margin-top: 10vh;">
                <v-img src="../../public/Loglive_512x512.png"></v-img>
            </div>
            <span class="logoBig">LØGLIVE</span>
            <v-btn style="margin-top: 15vh" large @click="goTo('contact')">CONTACT US</v-btn>
        </v-col> -->
    <!-- </v-row> -->


    <!-- <v-row class="my-16 py-16">
        <v-col cols="12">
            <div class="bigText text-h3 text-center">A PLATFORM FOR THE FUTURE</div>
        </v-col>
        <v-col>
            <v-container :style="mobile ? 'max-width:90%' : 'max-width:66.66%' ">
            <v-row>
                <v-col cols="12" md="6" class="xSmallText">By leveraging AI and big data, our platform offers a wealth of invaluable insights and optimizations that help businesses thrive in a competitive market. The platform has already made a significant impact, servicing around 35% of the South African industry as well as assisting governments globally with verification of information, often without the stakeholders even realizing it.</v-col>
                <v-col cols="12" md="6" class="xSmallText">In addition to the technological prowess of our platform, we recognize the importance of fostering a social community where businesses can collaborate, share knowledge, and form strategic partnerships. Our platform offers an interactive and user-friendly environment where stakeholders can communicate, engage, and work together to overcome the challenges they face collectively.</v-col>
                <v-col cols="12" md="6" class="xSmallText">One of the key features of our platform is the utilization of multiple data sources, which enables us to validate and cross-reference information to ensure the highest level of accuracy. With this approach, we can provide real-time updates, predictive analytics, and tailored recommendations, empowering businesses to make data-driven decisions with confidence. In turn, this leads to increased efficiency, reduced costs, and ultimately, a more sustainable and profitable industry.</v-col>
                <v-col cols="12" md="6" class="xSmallText">As a result, our platform not only streamlines the logistics process but also fosters a spirit of collaboration and innovation that drives the industry forward. In this ever-evolving landscape, staying ahead of the curve is essential, and our platform provides the tools and resources necessary for businesses to excel.</v-col>\
            </v-row>
        </v-container>
        </v-col>
    </v-row> -->

    

    <!-- <v-row class="my-16">
        <v-col cols="12" class="d-flex justify-center align-center py-16" style="flex-direction: column;">
                <v-img max-width="160" src="../../public/Loglive_512x512.png"></v-img>
            <span class="logoBig">LØGLIVE</span>
            <v-btn class="mt-16" large @click="goTo('contact')">CONTACT US</v-btn>
        </v-col>
    </v-row> -->

    <!-- <FooterComponent /> -->
</div>
<!-- </div> -->
</template>

<script>
// import FooterComponent from './FooterComponent.vue';
export default {
    components: {
        // FooterComponent
    },

    data() {
        return {
          
            mobile: false,
            showPassword: false,
            canvas: null,
            ctx: null,
            listEndObserver: null,
            animationFrameId: null,
            year: new Date().getFullYear(),
            mousePosition: {
                x: 0,
                y: 0
            },
            dots: {
                nb: 60,
                distance: 1000,
                d_radius: 50,
                maxConnections: 2, // Maximum connections per dot
                array: []
            }
        };
    },
    methods: {
        goTo(route) {
            if (this.$route.path !== `/${route.toLowerCase()}`) {
                this.$router.push({ path: `/${route.toLowerCase()}` })
                cancelAnimationFrame(this.animationFrameId);
                this.dots.array = [];

            }
        },
        createColorStyle(r, g, b) {
            return 'rgba(' + r + ',' + g + ',' + b + ', 0.4)';
        },
        averageColorStyles() {
            return this.createColorStyle('fff', 'fff', 'fff');
        },
        Color() {
            this.r = 255
            this.g = 255
            this.b = 255
            this.style = this.createColorStyle(this.r, this.g, this.b);
        },
        Dot() {
            this.x = Math.random() * this.canvas.width;
            this.y = Math.random() * this.canvas.height;
            this.vx = -.5 + Math.random();
            this.vy = -.5 + Math.random();
            this.radius = Math.random() * 2;
            this.color = new this.Color();
        },
        drawDot(dot) {
            this.ctx.beginPath();
            // Calculate opacity based on x position
            this.ctx.fillStyle = dot.color.style;
            this.ctx.arc(dot.x, dot.y, dot.radius, 0, Math.PI * 2, false);
            this.ctx.fill();
        },
        createDots() {
            for (let i = 0; i < this.dots.nb; i++) {
                this.dots.array.push(this.createDot());
            }
        },
        createColor() {
            const r = 255
            const g = 255
            const b = 255
            const style = this.createColorStyle(r, g, b);
            return { r, g, b, style };
        },
        createDot() {
            const x = Math.random() * this.canvas.width;
            const y = Math.random() * this.canvas.height;
            const vx = -.5 + Math.random();
            const vy = -.5 + Math.random();
            const radius = Math.random() * 2;
            const color = this.createColor();
            // Only 15% of dots will be connectable
            const isConnector = Math.random() < 0.15;
            return { x, y, vx, vy, radius, color, isConnector };
        },
        moveDots() {
            if (this.dots.array !== null && this.dots.array.length > 0) {
                for (let i = 0; i < this.dots.nb; i++) {
                    var dot = this.dots.array[i];
                    if (dot.y < 0 || dot.y > this.canvas.height) {
                        dot.vy = -dot.vy;
                    } else if (dot.x < 0 || dot.x > this.canvas.width) {
                        dot.vx = -dot.vx;
                    }
                    dot.x += dot.vx * 0.3;
                    dot.y += dot.vy * 0.3;
                }
            }
        },
        isInViewport() {
            const rect = this.canvas.getBoundingClientRect();
            const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
            const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

            return (
                rect.bottom > 0 &&
                rect.right > 0 &&
                rect.top < viewportHeight &&
                rect.left < viewportWidth
            );
        },

        setUpInterSectionObserver() {
            let options = {
                margin: "20px",
            };
            this.listEndObserver = new IntersectionObserver(
                this.handleIntersection,
                options
            );
            this.listEndObserver.observe(document.getElementById('canvas1'));
        },

        handleIntersection([entry]) {
            if (entry.isIntersecting) {
                this.animationFrameId = requestAnimationFrame(this.animateDots.bind(this));
            }
        },

        connectDots() {
            if (this.dots.array.length > 0 && this.dots.array !== null) {
                for (let i = 0; i < this.dots.nb; i++) {
                    const i_dot = this.dots.array[i];
                    if (!i_dot.isConnector) continue;
                    
                    // Find all dots within distance and sort by proximity
                    let nearbyDots = [];
                    for (let j = 0; j < this.dots.nb; j++) {
                        if (i === j) continue;
                        
                        const j_dot = this.dots.array[j];
                        const distance = Math.hypot(i_dot.x - j_dot.x, i_dot.y - j_dot.y);
                        
                        if (distance < this.dots.distance) {
                            nearbyDots.push({dot: j_dot, distance: distance});
                        }
                    }
                    
                    // Sort by distance and take closest 5 (or fewer if not enough nearby)
                    nearbyDots.sort((a, b) => a.distance - b.distance);
                    const connectionsCount = Math.min(this.dots.maxConnections, nearbyDots.length);
                    
                    // Draw connections to closest dots
                    for (let k = 0; k < connectionsCount; k++) {
                        const targetDot = nearbyDots[k].dot;
                        this.ctx.beginPath();
                        this.ctx.strokeStyle = this.createColorStyle(255, 255, 255);
                        this.ctx.moveTo(i_dot.x, i_dot.y);
                        this.ctx.lineTo(targetDot.x, targetDot.y);
                        this.ctx.stroke();
                        this.ctx.closePath();
                    }
                }
            }
        },
        drawDots() {
            if (this.dots.array.length > 0 && this.dots.array !== null) {
                for (let i = 0; i < this.dots.nb; i++) {
                    var dot = this.dots.array[i];
                    this.drawDot(dot);
                }
            }
        },
        animateDots() {
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.moveDots();
            this.connectDots();
            this.drawDots();
            if (this.isInViewport()) {
                this.animationFrameId = requestAnimationFrame(this.animateDots.bind(this));
            } else {
                cancelAnimationFrame(this.animationFrameId);
            }
        },
        updateMousePosition(event) {
            this.mousePosition.x = event.clientX;
            this.mousePosition.y = event.clientY;
        },
        resetMousePosition() {
            this.mousePosition.x = this.canvas.width / 2;
            this.mousePosition.y = this.canvas.height / 2;
        }
    },
    beforeMount() {
        this.mobile = this.$vuetify.breakpoint.mobile;
    },
    watch: {
        '$vuetify.breakpoint.mobile': function () {
            this.mobile = this.$vuetify.breakpoint.mobile;
        }
    },
    destroyed() {
        cancelAnimationFrame(this.animationFrameId);
        this.dots.array = [];
        if (this.listEndObserver) {
            this.listEndObserver.disconnect();
        }
    },
    unmount() {
        cancelAnimationFrame(this.animationFrameId);
        this.dots.array = [];
    },
    mounted() {
        if(this.mobile) return;
        this.canvas = document.getElementById('canvas1');
        this.ctx = this.canvas.getContext('2d');
        this.canvas.width = 550;
        this.canvas.height = 900;
        this.ctx.lineWidth = .3;
        this.ctx.strokeStyle = this.createColor().style;
        this.createDots();
        this.setUpInterSectionObserver();
    }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.v-text-field{
  background-color: rgba(34, 34, 34, 0.445) !important;
  margin: 1vh 1vw;
  color: rgb(224, 224, 224) !important;
  border: 0px solid rgb(138, 138, 138) !important;
  font-size: 14px;
}
.v-text-field ::v-deep input, .v-text-field ::v-deep .v-label, .v-text-field ::v-deep .v-input__control, .v-select ::v-deep .v-select__selection {
  color: rgb(224, 224, 224) !important;
}
.v-text-field ::v-deep fieldset {
  border: 1px solid rgb(59, 59, 59) !important;
}
.v-text-field ::v-deep .v-input__icon .v-icon {
  color: rgb(224, 224, 224) !important;
}
.loginCard {
  position: fixed;
  right: 8%;
  z-index: 10;
  top: 18%;
  width: 450px;
  height: 650px;
  border-left: 2px solid rgb(255, 255, 255, 0.36);
  border-right: 2px solid rgb(255, 255, 255, 0.36);
  border-top: 2px solid rgb(255, 255, 255, 0.2);
  border-bottom: 2px solid rgb(255, 255, 255, 0.2);
  border-image: linear-gradient(to bottom, 
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0)
  ) 1;
  /* border-radius: 20px 20px 20px 20px !important; */
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(5px);
  background: linear-gradient(to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.02) 30%,
    rgba(255, 255, 255, 0.02) 70%,
    rgba(255, 255, 255, 0) 100%
  );
}

.loginCard::after {
  content: '';
  position: fixed;
  right: 0;
  top: 0;
  width: 600px;
  height: 800px;
  background: radial-gradient(circle at center, rgba(47, 90, 170, 0.25) 0%, rgba(100, 149, 237, 0) 60%);
  filter: blur(50px);
  z-index: -1;
  pointer-events: none;
  transform: translate(70px, -90px);
}

.canvas1 {
    position: absolute;
    background: rgba(0, 0, 0, 0) !important;
    height: inherit;
    z-index: 10;
    width: inherit;
}

.canvasParent {
    position: absolute;
    right: 4%;
    top: 4%;
    z-index: 0;
    width: 650px;
    height: 900px;
}
.sentinel {
    height: 20px;
    width: 100%;
}

.ship {
    background-color: black !important;
    background: url(../../public/BoatFinal.png) no-repeat -2vw 2vh fixed;
    background-size: auto 100vh;
    /* position: absolute; */
    height: auto;
    z-index: 1;
    width: 100vw;
}
.shipMobile {
    background-color: black !important;
    background: url(../../public/BoatFinal.png) no-repeat -50vw 20vh fixed;
    background-size: auto 80vh;
    width: 100vw;
    height: auto;
    position: absolute;

}
.first {
    color: #b6b6b6;
    background-color: transparent !important;
    background: transparent;
    /* width: 100vw; */
    height: calc(100vh - 64px);
    overflow: hidden;
}
.second {
    background: rgba(0, 0, 0, 0) !important;
    /* position: relative; */
    z-index: 0;
    height: auto;
    min-height: 100vh;
    width: 100vw;
}

.system {
    background: transparent !important;
    position: relative; 
    /* background-image: radial-gradient(circle, #2d3339, #292d32, #25272b, #202124, #1b1b1d, #181819, #141415, #101010, #0d0d0d93, #09090927, #04040400, #00000000); */
    z-index: 0;
    height: 100vh;
    min-height: 100vh;
    width: 100vw;
    margin-top: 10rem
}

.logo {
  letter-spacing: 10px;
  font: 24px/16px Roboto,Helvetica,sans-serif;
  color: #ffffff;
}
.logoBig {
    font: 400 48px/56px Roboto, Helvetica, sans-serif;
    font-size: 60px;
}

.bigText {
    font: 700 42px/54px DIN_Regular, Arial, Verdana, sans-serif;
    color: rgb(204, 204, 204);
}

.midText {
    font: 700 32px/32px DIN_Regular, Arial, Verdana, sans-serif;
    letter-spacing: -1px;
    line-height: 28px;
    color: rgb(204, 204, 204);
    display: block;
    text-transform: uppercase;
    text-align: center;
}

.midMobile {
    font: 700 28px/32px DIN_Regular, Arial, Verdana, sans-serif;
    letter-spacing: -1px;
    line-height: 28px;
    text-align: center;
    color: rgb(204, 204, 204);
    display: block;
    text-transform: uppercase;
}

.smallText {
    font: 100 18px/20px DIN_Regular, Arial, Verdana, sans-serif;
    color: rgb(204, 204, 204);
}

.xSmallText {
    font: 100 16px/12px DIN_Regular, Arial, Verdana, sans-serif;
    color: rgb(204, 204, 204);
    line-height: 25px;
}
.cardTextHeader {
    font: 700 28px/49px DIN_Regular, Arial, Verdana, sans-serif;
    color: rgb(204, 204, 204);
    letter-spacing: -1px;
    line-height: 28px;
    text-transform: uppercase;
}

.infoHoverCard {
    height: 60vh;
    width: 100%;
    margin: 0 1vw;
    background-color: rgba(0, 0, 0, 0);
}

.gradientDiv {
    padding: 20px 15px;
    height: inherit;
    width: inherit;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to bottom, #000000f6, #060606ec, #0b0b0bc7, #10101073, #1414144f, #17171734, #1a1a1a33, #1d1d1d00, #2121211e, #25252500, #2a2a2a00, #2e2e2e00);
}

.transport,
.shipment,
.booking,
.documents {
    position: relative;
    overflow: hidden;
}

.transport::before,
.shipment::before,
.booking::before,
.documents::before {
    content: "";
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.8;
    color: rgb(255, 255, 255);
    transform: scale(1);
    transition: filter 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
}

.transport::before {
    background: url(../../public/trucks.jpg) no-repeat 0px 0px;
    background-size: 100% 100%;
}

.shipment::before {
    background: url(../../public/vessel.jpg) no-repeat -20px 0px;
    background-size: 108% 105%;
}

.booking::before {
    background: url(../../public/bookings.png) no-repeat 0px 0px;
    background-size: 120% 105%;

}

.descript {
    font: 100 16px/20px DIN_Regular, Arial, Verdana, sans-serif;
    color: rgb(224, 224, 224);
    line-height: 22px;
}

.documents::before {
    background: url(../../public/yard.png) no-repeat -20px -10px;
    background-size: 108% 105%;

}
.transport:hover::before,
.shipment:hover::before,
.booking:hover::before,
.documents:hover::before {
    transform: scale(1.1);
    opacity: 1;
}
</style>
